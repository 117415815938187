<template>
	<div>
		<AskProvenance ref="askProvenance" :provenance.sync="provenance" :all_lieux="all_lieux"/>
		 <div class="row" id="lieu">
            <div class="col-12">
            	<label>{{ $t("mouvement.lieu_residence") }}<sup>*</sup></label>
				<div class="row">
					<div class="col">
						<e-select
					        track-by="lieu_id"
							label="lieu_label"
					        v-model="lieu"
					        :options="residences"
					        :placeholder="$t('mouvement.rechercher_lieu')"
					        :loading="isLoadingLieu"
					        :sortable="false"
					        @input="setLieu"
					        ref="input"
					    >
					        <template slot="singleLabel" slot-scope="{ option }">{{ option.lieu_label }} {{ option.lieu_adresse }} {{ option.lieu_cp }} {{ option.lieu_ville }}</template>
					        <template slot="option" slot-scope="{ option }">{{ option.lieu_label }} {{ option.lieu_adresse }} {{ option.lieu_cp }} {{ option.lieu_ville }}</template>
				    	</e-select>
		            </div>
		        </div>
		    </div>
	    </div>

	    <div class="form-group">
			<label>{{ $t("mouvement.date_mouvement") }}<sup>*</sup></label>
            <e-datepicker id="date_ouverture" v-model="date" />
		</div>

        <div class="form-group">
			<label>{{ $t("mouvement.raison_mouvement") }}<sup>*</sup></label>
			<input class="form-control" type="text" id="mouvement_raison" :placeholder="$t('mouvement.raison_mouvement')" v-model="raison" required>
		</div>

		<div class="form-group">
            <label class="col-form-label">{{ $t('mouvement.choix_contact') }}</label>
			<div class="row">
				<div class="col">
					<e-select
						v-model="contact"
						id="contact_id"
						label="contact_label"
						track-by="contact_id"
						:placeholder="$t('acte.selectionnez_contact')"
						:selectedLabel="$t('global.selected_label')"
						:options="contacts"
						:allow-empty="true"
						:loading="isLoadingContact"
						:show-labels="false"
						:group-select="false"
						@input="setContact"
					>
						<template slot="option" slot-scope="{ option }">{{ option.contact_civility }} {{ option.contact_firstname }} {{ option.contact_lastname }}</template>
						<template slot="singleLabel" slot-scope="{ option }">{{ option.contact_civility }} {{ option.contact_firstname }} {{ option.contact_lastname }}</template>
						<template slot="noOptions">{{ $t('global.list_empty') }}</template>
					</e-select>
				</div>
				<div class="col-auto">
					<button class="btn btn-secondary" @click="addContact"><font-awesome-icon :icon="['far', 'plus']" /></button>
				</div>
			</div>
        </div>
	</div>
</template>

<script type="text/javascript">
	import { EventBus } from "EventBus"
	import Lieux from '@/mixins/Lieux'
	import Contact from "@/mixins/Contact.js"
	import ShutterMouvements from '@/mixins/shutters-manager/Mouvements.js'
	import Shutter from "@/mixins/Shutter.js"

	export default {
		name: 'WriteEntree',
		props: ['destination_lieu', 'provenance_lieu', 'mouvement_date', 'selected_contact', 'mouvement_raison'],
		mixins: [Lieux, Contact, ShutterMouvements, Shutter],
		data () {
			return {
				provenance: {},
				all_lieux: [],
				residences: [],
				lieu: {},
				isLoadingLieu: false,
				date: new Date(),
	            contact: null,
	            contacts: [],
	            isLoadingContact: false,
	            raison: ''
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.provenance = this.provenance_lieu
				this.lieu = this.destination_lieu
				this.date = this.mouvement_date
				this.contact = this.selected_contact
				this.raison = this.mouvement_raison

	            this.isLoadingLieu = true
	            this.all_lieux = await this.getLieux(false)
	            this.residences = this.all_lieux.filter(lieu => lieu.lieu_type == 1 && lieu.lieu_archive == 0)
				if(this.destination_lieu.lieu_id == 0) {
					if(this.residences.filter(lieu => lieu.lieu_default == 1 && lieu.lieu_type == 1).length > 0) {
						this.lieu = this.residences.filter(lieu => lieu.lieu_default == 1 && lieu.lieu_type == 1)[0]
						this.setLieu()
					}
					else if(this.residences.filter(lieu => lieu.lieu_type == 1).length == 1) {
						this.lieu = this.residences[0]
						this.setLieu()
					}
				}

	            this.isLoadingLieu = false

	            this.isLoadingContact = true
	            this.contacts = await this.getAllContact(true)
	            this.isLoadingContact = false

	            EventBus.$on('RefreshAllLieux', this.lieuFormSubmit)
			},

			setLieu() {
				return this.$emit('update:destination_lieu', this.lieu)
			},
			setContact() {
				return this.$emit('update:selected_contact', this.contact)
			},
	        async lieuFormSubmit() {
	            this.isLoadingLieu = true
	            this.all_lieux = await this.getLieux(false)
	            this.residences = this.all_lieux.filter(lieu => lieu.lieu_type == 1)
	            this.isLoadingLieu = false
	        },
			addContact() {
	            this.openAjoutContact()
	        },
			async contactFormSubmit() {
				this.isLoadingContact = true
				this.contacts = await this.getAllContact(true)
	            this.isLoadingContact = false
	        },
		},

		watch: {
			'provenance': {
				handler(val) {
	            	return this.$emit('update:provenance_lieu', val)
				},
				deep: true
			},
			'date' (val) {
				return this.$emit('update:mouvement_date', val)
			},
			'raison' (val) {
				return this.$emit('update:mouvement_raison', val)
			}
		},

		components: {
			AskProvenance: () => import('@/components/Mouvements/AskProvenance'),
		}
	}

</script>